import { Container, Navbar, Dropdown } from "react-bootstrap";

import { GoChevronDown } from "react-icons/go";
import { IoSettingsOutline } from "react-icons/io5";
import { PiCertificate } from "react-icons/pi";
import { BiLogOutCircle } from "react-icons/bi";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import profilePlaceHolder from "../../assets/images/default-avatar.jpg";
import WebService from "../../Services/WebService";
// import { profileSettings } from "../../config/action";

const Header = () => {
    let dispatch = useDispatch();
  // const profileDetailsFromRedux = useSelector(
  //   (state: any) => state?.profile?.profile_setting
  // );
  // let name =
  //   profileDetailsFromRedux?.first_name +
  //   " " +
  //   profileDetailsFromRedux?.last_name;
  // let email = profileDetailsFromRedux?.email;
  // let profilePhoto = profileDetailsFromRedux?.profile_photo;
  return (
    <>
      <header className="site-header">
        <Container fluid className="header-nav">
          <Navbar expand="lg">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <div className="d-flex align-items-center ms-auto gap-3">
              <Dropdown className="profile-dd" autoClose={true}>
                <Dropdown.Toggle>
                  <div className="d-flex gap-2">
                    <div>
                      <img
                        src={profilePlaceHolder}
                        width={35}
                        height={35}
                        className=" rounded-circle object-fit-cover"
                        alt=""
                      />
                    </div>
                    <div>
                      <p className="font-15 mb-0 text-dark font-medium">
                        {/* {name} */}
                      </p>
                      <p className="font-12 mb-0 font-light text-dark">
                        {/* {email} */}
                      </p>
                    </div>
                  </div>
                  <GoChevronDown size={16} className="icon" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="end">
                  <Link to="/settings" className="dropdown-item">
                    <IoSettingsOutline
                      size={18}
                      className="me-1 align-text-top"
                    />{" "}
                    Setting
                  </Link>
                  <Link to="/subscription" className="dropdown-item">
                    <PiCertificate size={18} className="me-1 align-text-top" />{" "}
                    Subscription
                  </Link>
                  <Link
                    to="/login"
                    // onClick={() =>{dispatch(profileSettings({}));; WebService.logout();}}
                    className="dropdown-item"
                  >
                    <BiLogOutCircle size={18} className="me-1 align-text-top" />{" "}
                    Logout
                  </Link>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Navbar>
        </Container>
      </header>
    </>
  );
};
export default Header;
