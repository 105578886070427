import { Link } from 'react-router-dom'; 
import Logo from '../../assets/images/SecuB-logo.svg'
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { PiUserSquare } from "react-icons/pi";
import { RiArchiveDrawerLine } from "react-icons/ri";
import { TbMessageChatbot } from "react-icons/tb";
import { TbUserCheck } from "react-icons/tb";
import { GoClock } from "react-icons/go";
import { TbMessageCircleQuestion } from "react-icons/tb";
import { IoIosStarOutline } from "react-icons/io";
import { TfiHeadphoneAlt } from "react-icons/tfi";

import { MdSpaceDashboard, MdCategory, MdLocalGroceryStore, MdLayers, MdGroup, MdCoPresent, MdManageAccounts, MdOutlinePublicOff,MdGppGood } from "react-icons/md";
import { BiSolidOffer } from "react-icons/bi";

const VerticalMenu = () => {
    return (
        <>
            <div id="vertical_menu" className="verticle-menu">
                <div className='text-center pb-3'>
                    <img src={Logo} width={118}  alt='' />
                </div>
                <div className="menu-list">
                    
                <Link id="t-1" to={'/manage-customers'} className="menu-item"> <MdOutlineSpaceDashboard className="menu-icon" /> <span className='nav-text'>Manage Customers</span></Link>
                    <Link id="t-1" to={'/manage-questionary'} className="menu-item"> <PiUserSquare className="menu-icon" /> <span className='nav-text'>Manage Questionaries</span></Link>
                    <Link id="t-1" to={'/manage-payments'} className="menu-item"> <TbMessageCircleQuestion className="menu-icon" /> <span className='nav-text'>Manage Payments</span></Link>
                    <Link id="t-1" to={'/manage-subscription'} className="menu-item"> <RiArchiveDrawerLine className="menu-icon" /> <span className='nav-text'>Manage Subscriptions</span></Link>
                    <Link id="t-1" to={'/manage-roles'} className="menu-item"> <TbMessageChatbot className="menu-icon" /> <span className='nav-text'>Manage Roles</span></Link>
                    <Link id="t-1" to={'/manage-users'} className="menu-item"> <TbUserCheck className="menu-icon" /> <span className='nav-text'>Manage Users</span></Link>
                    <Link id="t-1" to={'/manage-cms'} className="menu-item"> <GoClock className="menu-icon" /> <span className='nav-text'>Manage CMS</span></Link>
                    <Link id="t-1" to={'/settings'} className="menu-item"> <IoIosStarOutline className="menu-icon" /> <span className='nav-text'>Settings</span></Link>
                </div>
            </div>
        </>
    )
}
export default VerticalMenu;