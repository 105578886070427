

import 'bootstrap/dist/css/bootstrap.css'
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';
import Navigation from './navigation';
import { ToastContainer } from 'react-toastify';
function App() {
  return (

    <div className="App">
     <Navigation />
     <ToastContainer />
    </div>
  );
}

export default App;
