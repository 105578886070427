import { Outlet } from "react-router-dom";
import PageFooter from "../components/Common/PageFooter";
import Header from "./Header/Header";
import VerticalMenu from "./LeftMenu/VerticalMenu";

const Main = () => {
  return (
    <>
      
      <div id="main-app" className="layout-veritcle-menu">
        <VerticalMenu />
        <div className="app-body">
        <Header />
          <div className="app-page">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
